<template>
    <Card title="许可证详情">
      <div style="padding-top:24px">

        <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item label="排水户" :span="2">
        {{ detail.main ? detail.main.name : '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="排水户类型">
        <DataDictFinder />
        {{ detail.main ? getType(detail.main.firmType ): '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="许可证编号">
        {{ detail.number }}
      </a-descriptions-item>
      <a-descriptions-item label="发证机关">
        {{ detail.publishGov }}
      </a-descriptions-item>
      <a-descriptions-item label="发证日期">
        {{ detail.publishDate }}
      </a-descriptions-item>
      <a-descriptions-item label="有效期">
        {{ detail.validDateFrom }} ~ {{ detail.validDateTo }}
      </a-descriptions-item>
      
 
      <a-descriptions-item label="文件">

          <a v-if="detail.licenseFile" :href="
                 detail.licenseFile.indexOf('http') === 0 ? detail.licenseFile : 'https://' + 
                 detail.licenseFile" target="_blank">查看</a>

      </a-descriptions-item>
    </a-descriptions>

    <div style="padding-top: 1em"></div> 

    <div style="padding-bottom: 8px;">版本记录</div>
     
    <a-table :pagination="false" bordered :dataSource="Array.isArray( detail.histories ) ? detail.histories : []" :loading="loading" rowKey="id">

        <a-table-column title="许可证编号" data-index="number" align="center"></a-table-column>
        <a-table-column title="类型" data-index="type" align="center"></a-table-column>
        <a-table-column title="版本号" data-index="version" align="center"></a-table-column>

        <a-table-column title="有效期" align="center">
          <template slot-scope="text">
            <span>
              {{ text.validDateFrom }} ~ {{ text.validDateTo }}
            </span>
          </template>
        </a-table-column>
 
      </a-table>
  
      <div style="padding-top: 1em"></div> 

<div style="padding-bottom: 8px;">排水说明</div>
        <div v-html="renderRemark(detail.remark)" style="border: 1px solid #fff;padding: 8px;line-height: 1.6;">
        </div>
      </div>


      <div class="center" style="margin-top: 48px;padding-bottom: 48px;">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
          </a-space>
        </div>

    </Card>
  </template>
  
  <script>
  import { mapGetters} from 'vuex'
  import request from "@/api/request";
  
  function fetchDetail(params) {
    return request({
      url: "/model-analysis/discharger/license/query/" + params.id,
    });
  }
  
  export default {
    data() {
      return {
        loading: false,
        list: [],
        path: '',
        detail: {},
      };
    },

    computed: {
      ...mapGetters("setting", ["findDataDict"]),
    dict() {
      return this.findDataDict('discharger')
    },
    typeList() {
      const res = this.dict.find(item => item.value === 'firmType')
            if (res && Array.isArray(res.children)) {
                return res.children
            } else {
                return []
            }
    },
    },
  
    mounted() {
                  const { id } = this.$route.query;
  
          if (id) {
            this.loading = true;
            fetchDetail({
            id
          }).then(res => {
      
            this.detail = res || {}
            this.path = res.licenseFile || ''
          }).finally(() => {
            this.loading = false;
          })
  
          }
  
    },  
  
    methods: {

      getType(type) {
      console.log('type', type, this.typeList);
      const obj = this.typeList.find(item => item.value === type)
      return obj ? obj.name : ''
    },  


        renderRemark(text) {
            return text?  text.replace(/\n/g, '<br />') : ''
        },
      cancel() {
        this.$router.go(-1);
      },
    },
  };
  </script>